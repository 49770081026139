export const eventsList = [
    {
        'id': 'ssm',
        'name': "Satsang Suhrad Milan",
        'displayName': "Satsang Suhrad Milan",
        'description': "Annual Karyakar Appreciation Sabha (Only for invited guests)",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'eventDays': ['Friday, January 19th - 5:30 PM'],
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpSSM',
            'eventId': 'ssm',
            'eventName': 'Satsang Suhrad Milan - 2024',
            'displayName': 'Satsang Suhrad Milan',
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'time': '5:30 PM - 9:00 PM'
        },
        'hideList': true,
        'active': true
    },

    // Student Sabha - IIT
    {
        'id': 'student-sabha',
        'name': "Student Sabha - October",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'student-sabha-oct',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
            'date': 'Thursday, October 17th, 2024',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday October 17th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Student Sabha - UIC
    {
        'id': 'uic-student-sabha',
        'name': "Student Sabha - September",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'uic-student-sabha-sep',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
            'date': 'Thursday, September 12th, 2024',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday September 12th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Hindi Sabha
    {
        'id': 'hindi-sabha',
        'name': "Hindi Sabha - October",
        'displayName': "Hindi Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'hindi-sabha-october',
            'eventName': 'Hindi Sabha',
            'displayName': 'Hindi Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'date': 'Sunday, October 13th, 2024',
            'time': '4:00 PM - 5:30 PM',
            'eventDays': ['Sunday October 13th - 4:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Diwali 2024
    {
        'id': 'diwali-kdc',
        'name': "Kid's Diwali Celebration",
        'displayName': "Kid's Diwali Celebration",
        'description': "Growing up in a diverse continent like North America, children experience an array of cultures, languages, values, and beliefs. Amidst this, BAPS strives to preserve Hindu culture and Indian traditions abroad. Through the annual Kids Diwali Celebration, families will have the opportunity to experience culture through games, food and fun!",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Saturday, October 19th',
        'time': '11:00 AM - 6:00 PM',
        'eventStartDateTime': '2024-10-19T11:00',
        'eventEndDateTime': '2024-10-19T18:00',
        'image': 'kdc.jpg',
        'imageURL': 'https://chicagomandir.org/static/media/kdc.a6bd136d.jpg',
        'actionLink': {
            'text': 'Register your family',
            'newTab': true,
            'url': 'https://tinyurl.com/CHICAGO-KDC-2024'
        },
        'active': true
    },

    {
        'id': 'diwali',
        'name': "Chopda Pujan 2024",
        'displayName': "Sharda Pujan & Diwali",
        'description': "One of the most auspicious days in the Hindu calendar will be celebrated with great devotion and splendor. A ceremony, known as Sharda Pujan, will take place in the evening. Hundreds of new account books – in a variety of paper and electronic forms – are sanctified through the showering of flowers, petals and rice grains amid the chanting of Vedic hymns and prayers.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Thursday, October 31st',
        'time': 'Session 1: 4:45 PM | Session 2: 6:15 PM',
        'eventStartDateTime': '2024-10-31T16:45',
        'eventEndDateTime': '2024-10-19T19:00',
        'image': 'diwali.jpg',
        'imageURL': 'https://chicagomandir.org/static/media/diwali.71f99f24.jpg',
        'eventDays': ['Thursday, October 31st - 4:45 PM', 'Thursday, October 31st - 6:15 PM'],
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpEvent',
            'eventId': 'diwali',
            'eventName': 'Chopda Pujan 2024',
            'displayName': 'Sharda Pujan & Diwali',
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'time': 'Session 1: 4:45 PM | Session 2: 6:15 PM',
            'description': 'Fill out form below to register for the event. We look forward to seeing you.',
            'eventDays': ['Thursday, October 31st - 4:45 PM', 'Thursday, October 31st - 6:15 PM'],
        },
        'active': true
    },

    {
        'id': 'diwali-fireworks',
        'name': "Diwali Fireworks Show",
        'displayName': "Diwali Fireworks Show",
        'description': "The Diwali Fireworks and Sound Show is a unique fusion of light, music and a cultural program. Please ensure to arrive early to have mandir darshan and proceed to the front of the Mandir to view the fireworks show.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Friday, November 1st',
        'time': '7:30 PM - 8:30 PM',
        'eventStartDateTime': '2024-11-01T19:30',
        'eventEndDateTime': '2024-11-01T20:30',
        'imageURL': 'https://chicagomandir.org/static/media/fireworks.7796fe2b.jpg',
        'image': 'DiwaliFireworks.jpeg',
        'active': true
    },

    {
        'id': 'diwali-annakut',
        'name': "Grand Annakut Darshan",
        'displayName': "Grand Annakut Darshan",
        'description': "The day following Diwali is the beginning of the Hindu New Year. The Hindu New Year is traditionally celebrated with the Annakut – literally meaning, a mountain of food. Every year, thousands of vegetarian delicacies are offered in devotional gratitude for the past year and to seek blessings for the New Year ahead. We cordially invite you for darshan of the Annakut with family and friends.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Saturday, November 2nd',
        'time': '12:00 PM - 8:00 PM',
        'eventStartDateTime': '2024-11-02T12:00',
        'eventEndDateTime': '2024-11-02T20:00',
        'imageURL': 'https://chicagomandir.org/static/media/annakut.78d3c389.jpg',
        'image': 'Annakut.jpeg',
        'active': true
    },

    {
        'id': 'diwali-samaiyo',
        'name': "Diwali Samaiyo",
        'displayName': "Diwali Samaiyo",
        'description': "Celebrate the first Gujarati satsang sabha (weekly spiritual assembly) of the Hindu New Year with spiritual discourses, inspiring videos, and kirtan-bhakti.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Sat, November 9th | Sun, November 10th',
        'time': '4:00 PM - 6:00 PM',
        'eventStartDateTime': '2024-11-02T12:00',
        'eventEndDateTime': '2024-11-02T20:00',
        'imageURL': 'https://chicagomandir.org/static/media/diwali_samaiyo.f1a915ae.jpg',
        'image': 'diwali_samaiyo.jpg',
        'active': true
    }


    // Sample Event
    // {
    //     'id': 'psmjj',
    //     'name': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'displayName': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'description': "Pramukh Swami Maharaj inspired thousands through his deep spirituality, humility and dedication to service. Pramukh Swami Maharaj’s birthday will be celebrated in a special event by women for women on December 17, 2023.",
    //     'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //     'date': 'Sunday, December 17th',
    //     'time': '4:00 PM - 6:00 PM',
    //     'eventStartDateTime': '2024-12-18T16:00',
    //     'eventEndDateTime': '2024-12-18T18:00',
    //     'image': 'PSMMahilaSamaiyo.jpeg',
    //     'imageURL': 'https://www.baps.org/Data/Sites/1/Media/LocationImages/upcomingevents/WebImages/pramukh_swami_maharaj_007.jpg',
    //     'eventDays': ['Sunday, December 17th - 12:00 PM'],
    //     'needRsvp': {
    //         'text': 'Register Now'
    //     },
    //     'formData': {
    //         'formName': 'rsvpEvent',
    //         'eventId': 'psmjj',
    //         'eventName': 'Pramukh Swami Maharaj Birthday - Mandir',
    //         'displayName': 'Pramukh Swami Maharaj Birthday',
    //         'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //         'time': '4:00 PM - 6:00 PM',
    //         'description': 'Join us for a cordial gathering of the Hindu community in a unique celebration of unity and harmony. Let us connect to exchange ideas and inspire collaboration. Let us grow together.',
    //         'eventDays': ['Sunday January 7th - 4:00 PM', 'Sunday January 8th - 4:00 PM'],
    //     },
    //     'active': false
    // },
];